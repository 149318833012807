<template>
  <v-menu
    bottom
    left
    :disabled="loading"
  >
    <template #activator="{ on: menuOn, attrs: menuAttrs }">
      <v-btn
        icon
        v-bind="menuAttrs"
        v-on="menuOn"
      >
        <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
      </v-btn>
    </template>

    <template #default>
      <v-list
        :disabled="loading"
        dense
      >
        <v-subheader>操作</v-subheader>
        <DataTableCopyListItemBtn
          v-if="items.length && headers.length"
          :disabled="loading"
          :headers="headers"
          :items="items"
        />
      </v-list>
    </template>
  </v-menu>
</template>

<script>
import { mdiDotsVertical } from '@mdi/js'
import DataTableCopyListItemBtn from '@/views/v2-temp/common/composables/components/DataTableCopyListItemBtn.vue'

export default {
  components: {
    DataTableCopyListItemBtn,
  },
  props: {
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    availableUsers: {
      type: Array,
      default: () => [],
    },
    items: {
      type: Array,
      default: () => [],
    },
    headers: {
      type: Array,
      default: () => [],
    },
  },
  setup() {
    return {
      icons: {
        mdiDotsVertical,
      },
    }
  },
}
</script>
