var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.displayModeModel === 'monthly_daily')?_c('RangeDailyTable',{attrs:{"aggregation":_vm.aggregatable,"loading":_vm.loading},on:{"save":function($event){return _vm.$emit('save', $event)},"reload":function($event){return _vm.$emit('reload', $event)}},scopedSlots:_vm._u([{key:"toolbar-title",fn:function(ref){
var aggregation = ref.aggregation;
return [(aggregation.attributes)?_c('ClubReportTableTitle',{attrs:{"selected-club-report-id":_vm.selectedClubReportIdModel,"club-reports":_vm.clubReports,"default-title":aggregation.attributes.name},on:{"update:selectedClubReportId":function($event){_vm.selectedClubReportIdModel=$event},"update:selected-club-report-id":function($event){_vm.selectedClubReportIdModel=$event}}}):_vm._e()]}},{key:"toolbar-filters",fn:function(){return [_c('ClubReportTableFilters',{attrs:{"display-mode":_vm.displayModeModel},on:{"update:displayMode":function($event){_vm.displayModeModel=$event},"update:display-mode":function($event){_vm.displayModeModel=$event}}})]},proxy:true},{key:"toolbar-menu",fn:function(ref){
var headers = ref.headers;
var items = ref.items;
return [_c('ClubReportTableMenu',{attrs:{"loading":_vm.loading,"headers":headers,"items":items}})]}}],null,false,396358431)}):(_vm.displayModeModel === 'monthly_sum')?_c('RangeSumTable',{attrs:{"aggregation":_vm.aggregatable,"loading":_vm.loading},on:{"reload":function($event){return _vm.$emit('reload', $event)}},scopedSlots:_vm._u([{key:"toolbar-title",fn:function(ref){
var aggregation = ref.aggregation;
return [(aggregation.attributes)?_c('ClubReportTableTitle',{attrs:{"selected-club-report-id":_vm.selectedClubReportIdModel,"club-reports":_vm.clubReports,"default-title":aggregation.attributes.name},on:{"update:selectedClubReportId":function($event){_vm.selectedClubReportIdModel=$event},"update:selected-club-report-id":function($event){_vm.selectedClubReportIdModel=$event}}}):_vm._e()]}},{key:"toolbar-filters",fn:function(){return [_c('ClubReportTableFilters',{attrs:{"display-mode":_vm.displayModeModel},on:{"update:displayMode":function($event){_vm.displayModeModel=$event},"update:display-mode":function($event){_vm.displayModeModel=$event}}})]},proxy:true},{key:"toolbar-menu",fn:function(ref){
var headers = ref.headers;
var items = ref.items;
return [_c('ClubReportTableMenu',{attrs:{"loading":_vm.loading,"headers":headers,"items":items}})]}}])}):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }