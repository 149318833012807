<template>
  <v-menu
    bottom
    left
    :disabled="loading"
  >
    <template #activator="{ on: menuOn, attrs: menuAttrs }">
      <v-btn
        icon
        v-bind="menuAttrs"
        v-on="menuOn"
      >
        <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
      </v-btn>
    </template>

    <template #default="menu">
      <v-list
        :disabled="loading"
        dense
      >
        <v-subheader>操作</v-subheader>
        <ReportUsersDialogListItem
          :disabled="loading"
          :report-users="userReport.attributes.reportUsers.data"
          :available-users="availableUsers"
          @fetch-available-users="$emit('fetch-available-users', $event)"
          @create="$emit('create-report-user', $event)"
          @destroy="$emit('destroy-report-user', $event)"
          @cancel="menu.value = false"
        />
        <DataTableCopyListItemBtn
          v-if="items.length && headers.length"
          :disabled="loading"
          :headers="headers"
          :items="items"
        />
      </v-list>
    </template>
  </v-menu>
</template>

<script>
import { mdiDotsVertical } from '@mdi/js'
import ReportUsersDialogListItem from './ReportUsersDialogListItem.vue'
import DataTableCopyListItemBtn from '@/views/v2-temp/common/composables/components/DataTableCopyListItemBtn.vue'

export default {
  components: {
    ReportUsersDialogListItem,
    DataTableCopyListItemBtn,
  },
  props: {
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    availableUsers: {
      type: Array,
      default: () => [],
    },
    items: {
      type: Array,
      default: () => [],
    },
    headers: {
      type: Array,
      default: () => [],
    },
    userReport: {
      type: Object,
      required: true,
      default: () => ({
        id: null,
        type: 'userReport',
        attributes: {
          id: null,
          name: null,
          description: null,
          position: null,
          type: 'userReport',
          businessRules: {
            data: [
              {
                id: null,
                type: 'businessRule',
                attributes: {
                  id: null,
                  type: null,
                  name: null,
                  position: null,
                },
              },
            ],
          },
          reportUsers: {
            data: [
              {
                id: null,
                type: 'reportUser',
                attributes: {
                  id: null,
                  reportId: null,
                  userId: null,
                  user: {
                    data: {
                      id: null,
                      type: 'user',
                      attributes: {
                        id: null,
                        name: null,
                        role: null,
                      },
                    },
                  },
                },
              },
            ],
          },
        },
      }),
    },
  },
  setup() {
    return {
      icons: {
        mdiDotsVertical,
      },
    }
  },
}
</script>
