<template>
  <v-data-table
    :loading="loading"
    :headers="aggregationTable.headers"
    :items="aggregationTable.items"
    :header-props="{ sortByText: 'ソート' }"
    :footer-props="{ itemsPerPageText: '行/ページ:' }"
    no-data-text="データがありません"
    loading-text="読込中..."
    :hide-default-footer="aggregationTable.items.length === 1"
    class="aggregation-table"
    :class="{ 'fixed-first-column': isUserAggregation }"
  >
    <template #top>
      <v-toolbar
        flat
        color="transparent"
      >
        <v-toolbar-title>{{ aggregationTable.name }}</v-toolbar-title>
        <v-spacer />

        <v-btn
          icon
          depressed
          :ripple="false"
          :loading="loading"
          :disabled="loading"
          @click.prevent="$emit('reload', aggregationTable.id)"
        >
          <v-icon>
            {{ icons.mdiReload }}
          </v-icon>
        </v-btn>
        <v-divider
          vertical
          inset
          class="mx-2"
        />
        <v-menu
          bottom
          left
        >
          <template #activator="{ on: menuOn, attrs: menuAttrs }">
            <v-btn
              icon
              v-bind="menuAttrs"
              v-on="menuOn"
            >
              <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
            </v-btn>
          </template>

          <template #default="menu">
            <v-list
              :disabled="loading"
              dense
            >
              <v-subheader>操作</v-subheader>
              <AggregationUsersDialogListItem
                v-if="isUserAggregation"
                :disabled="loading"
                :aggregation-users="aggregationUsers"
                :available-users="availableUsers"
                @create="$emit('create-user-aggregation', $event)"
                @fetch-available-users="$emit('fetch-available-users', $event)"
                @destroy="$emit('destroy-user-aggregation', $event)"
                @cancel="menu.value = false"
              />
              <DataTableCopyListItemBtn
                :disabled="loading"
                :headers="aggregationTable.headers"
                :items="aggregationTable.items"
              />
            </v-list>
          </template>
        </v-menu>
      </v-toolbar>
    </template>

    <!-- eslint-disable-next-line vue/no-useless-template-attributes -->
    <template
      v-for="header in aggregationTable.businessRuleHeaders"
      #[`item.${header.value}`]="{ item }"
    >
      <v-menu
        :key="`business-rule-result-input-for-current-club-business-rule-id-${header.value}`"
        :close-on-content-click="false"
        :nudge-width="200"
        :disabled="isNotEditable(item, header.value)"
        @input="onMenuInput($event, item, header.value)"
      >
        <template #activator="{ on: editMenuOn, attrs: editMenuAttrs }">
          <span
            v-bind="editMenuAttrs"
            v-on="editMenuOn"
          >
            <v-checkbox
              v-if="isBooleanItem(item, header.value)"
              :input-value="item[header.value]"
              :disabled="loading"
              readonly
              dense
              hide-details
              :class="{ 'new-item': !isNotEditable(item, header.value) && isNew(item, header.value) }"
            />
            <v-text-field
              v-else-if="isNumberItem(item, header.value)"
              :value="numberText(item, header.value)"
              :disabled="loading"
              readonly
              dense
              hide-details
              :class="{ 'new-item': !isNotEditable(item, header.value) && isNew(item, header.value) }"
            />
            <v-text-field
              v-else
              :value="item[header.value]"
              :disabled="loading"
              :type="inputType(item, header.value)"
              readonly
              dense
              hide-details
              :class="{ 'new-item': !isNotEditable(item, header.value) && isNew(item, header.value) }"
            />
          </span>
        </template>
        <template #default="editMenu">
          <v-card>
            <v-card-subtitle>{{ header.text }}</v-card-subtitle>
            <v-card-text>
              <v-checkbox
                v-if="isBooleanItem(item, header.value)"
                v-model="item[header.value]"
                autofocus
                dense
                hide-details
                :disabled="isNotEditable(item, header.value)"
              />
              <v-text-field
                v-else-if="isNumberItem(item, header.value)"
                v-model="item[header.value]"
                v-format-number-on-blur
                autofocus
                type="number"
                inputmode="numeric"
                pattern="[0-9]*"
                dense
                hide-details
                :disabled="isNotEditable(item, header.value)"
              />
              <v-text-field
                v-else
                v-model="item[header.value]"
                autofocus
                :type="inputType(item, header.value)"
                dense
                hide-details
                :disabled="isNotEditable(item, header.value)"
              />
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn
                text
                small
                @click="editMenu.value = false"
              >
                キャンセル
              </v-btn>
              <v-btn
                color="primary"
                small
                :disabled="isNotEditable(item, header.value)"
                @click="saveResultValue(item, header.value, item[header.value], editMenu)"
              >
                保存
              </v-btn>
            </v-card-actions>
          </v-card>
        </template>
      </v-menu>
    </template>
  </v-data-table>
</template>

<script>
import { mdiReload, mdiDotsVertical } from '@mdi/js'
import formatNumberOnBlur from '@/directives/formatNumberOnBlur'
import useAggregationTable from '@/views/v2-temp/eod-closing/composables/useAggregationTable'
import AggregationUsersDialogListItem from '@/views/v2-temp/eod-closing/components/AggregationUsersDialogListItem.vue'
import DataTableCopyListItemBtn from '@/views/v2-temp/common/composables/components/DataTableCopyListItemBtn.vue'

export default {
  components: {
    AggregationUsersDialogListItem,
    DataTableCopyListItemBtn,
  },
  directives: {
    formatNumberOnBlur,
  },
  props: {
    aggregation: {
      type: Object,
      default: () => {
        return {
          attributes: {
            id: null,
            name: '',
            description: '',
            businessRules: { data: [] },
            results: { data: [] },
            contexts: { data: [{ attributes: { results: { data: [] } } }] },
          },
        }
      },
    },
    aggregationUsers: {
      type: Array,
      default: () => [],
    },
    availableUsers: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const {
      aggregationTable,
      isUserAggregation,
      isBooleanItem,
      isNumberItem,
      isNew,
      isNotEditable,
      inputType,
      numberText,
      saveResultValue,
      onMenuInput,
    } = useAggregationTable(props, emit)

    return {
      isBooleanItem,
      isNumberItem,
      numberText,
      isNotEditable,
      isUserAggregation,
      inputType,
      isNew,
      aggregationTable,
      saveResultValue,
      onMenuInput,
      icons: {
        mdiReload,
        mdiDotsVertical,
      },
    }
  },
}
</script>

<style lang="scss">
@import '~@core/preset/preset/mixins.scss';

.aggregation-table {
  white-space: nowrap;

  /* 新規作成のフィールドに適用するスタイル */
  .new-item .v-input__slot::before {
    border-bottom: 2px solid #9155FD !important; /* アンダーラインの色を変更（例: 青色） */
  }

  .new-item .v-input__slot::after {
    border-bottom: 2px solid #9155FD !important; /* フォーカス時のアンダーラインの色を変更（例: 濃い青色） */
  }

  /* チェックボックスのアンダーラインも変更 */
  .new-item .v-input--selection-controls__input:after {
    border-bottom: 2px solid #9155FD !important;
  }

  .new-item .v-input--selection-controls__input:focus:after {
    border-bottom: 2px solid #9155FD !important;
  }
}
</style>
