<!-- eslint-disable vue/no-deprecated-v-bind-sync -->
<template>
  <RangeDailyTable
    v-if="displayModeModel === 'monthly_daily'"
    :aggregation="aggregatable "
    :loading="loading || isPending"
    @save="$emit('save', $event)"
    @reload="$emit('reload', $event)"
  >
    <template #toolbar-title="{ aggregation }">
      <UserReportTableTitle
        v-if="aggregation.attributes"
        :selected-user-report-id.sync="selectedUserReportIdModel"
        :user-reports="userReports"
        :default-title="aggregation.attributes.name"
      />
    </template>
    <template #toolbar-filters>
      <UserReportTableFilters
        :display-mode.sync="displayModeModel"
        :user-id.sync="userIdModel"
        :user-report="userReport"
        @change-display="$emit('change-display', $event)"
      />
    </template>
    <template #toolbar-menu="{ headers, items }">
      <UserReportTableMenu
        :loading="loading || isPending"
        :available-users="availableUsers"
        :user-report="userReport"
        :headers="headers"
        :items="items"
        @fetch-available-users="$emit('fetch-available-users', $event)"
        @create-report-user="$emit('create-report-user', $event)"
        @destroy-report-user="$emit('destroy-report-user', $event)"
      />
    </template>
  </RangeDailyTable>

  <RangeSumTable
    v-else-if="displayModeModel === 'monthly_sum'"
    :aggregation="aggregatable"
    :loading="loading || isPending"
    @reload="$emit('reload', $event)"
  >
    <template #toolbar-title="{ aggregation }">
      <UserReportTableTitle
        v-if="aggregation.attributes"
        :selected-user-report-id.sync="selectedUserReportIdModel"
        :user-reports="userReports"
        :default-title="aggregation.attributes.name"
      />
    </template>
    <template #toolbar-filters>
      <UserReportTableFilters
        :display-mode.sync="displayModeModel"
        :user-id.sync="userIdModel"
        :user-report="userReport"
        @change-display="$emit('change-display', $event)"
      />
    </template>
    <template #toolbar-menu="{ headers, items }">
      <UserReportTableMenu
        :loading="loading || isPending"
        :available-users="availableUsers"
        :user-report="userReport"
        :headers="headers"
        :items="items"
        @fetch-available-users="$emit('fetch-available-users', $event)"
        @create-report-user="$emit('create-report-user', $event)"
        @destroy-report-user="$emit('destroy-report-user', $event)"
      />
    </template>
  </RangeSumTable>

  <RangeSumTable
    v-else-if="displayModeModel === 'monthly_all_users_sum'"
    :aggregation="aggregatable"
    :loading="loading"
    @reload="$emit('reload', $event)"
  >
    <template #toolbar-title="{ aggregation }">
      <UserReportTableTitle
        v-if="aggregation.attributes"
        :selected-user-report-id.sync="selectedUserReportIdModel"
        :user-reports="userReports"
        :default-title="aggregation.attributes.name"
      />
    </template>
    <template #toolbar-filters>
      <UserReportTableFilters
        :display-mode.sync="displayModeModel"
        :user-id.sync="userIdModel"
        :user-report="userReport"
        @change-display="$emit('change-display', $event)"
      />
    </template>
    <template #toolbar-menu="{ headers, items }">
      <UserReportTableMenu
        :loading="loading"
        :available-users="availableUsers"
        :user-report="userReport"
        :headers="headers"
        :items="items"
        @fetch-available-users="$emit('fetch-available-users', $event)"
        @create-report-user="$emit('create-report-user', $event)"
        @destroy-report-user="$emit('destroy-report-user', $event)"
      />
    </template>
  </RangeSumTable>
</template>

<script>
import {
  computed,
} from '@vue/composition-api'
import { mdiReload, mdiDotsVertical } from '@mdi/js'
import { useVModel } from '@vueuse/core'
import RangeDailyTable from './RangeDailyTable.vue'
import RangeSumTable from './RangeSumTable.vue'
import UserReportTableTitle from './UserReportTableTitle.vue'
import UserReportTableFilters from './UserReportTableFilters.vue'
import UserReportTableMenu from './UserReportTableMenu.vue'

export default {
  components: {
    RangeDailyTable,
    RangeSumTable,
    UserReportTableTitle,
    UserReportTableFilters,
    UserReportTableMenu,
  },
  props: {
    selectedUserReportId: {
      type: [String, Number],
      required: true,
    },
    displayMode: {
      type: String,
      required: true,
      default: 'monthly_daily',
    },
    userId: {
      required: true,
      default: null,
      validator: val => (val === null || typeof val === 'number'),
    },
    report: {
      type: Object,
      required: true,
      default: () => {},
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    availableUsers: {
      type: Array,
      default: () => [],
    },
    userReports: {
      type: Array,
      required: true,
      default: () => [],
    },
    userReport: {
      type: Object,
      required: true,
      default: () => ({
        id: null,
        type: 'userReport',
        attributes: {
          id: null,
          name: null,
          description: null,
          position: null,
          type: 'userReport',
          businessRules: {
            data: [
              {
                id: null,
                type: 'businessRule',
                attributes: {
                  id: null,
                  type: null,
                  name: null,
                  position: null,
                },
              },
            ],
          },
          reportUsers: {
            data: [
              {
                id: null,
                type: 'reportUser',
                attributes: {
                  id: null,
                  reportId: null,
                  userId: null,
                  user: {
                    data: {
                      id: null,
                      type: 'user',
                      attributes: {
                        id: null,
                        name: null,
                        role: null,
                      },
                    },
                  },
                },
              },
            ],
          },
        },
      }),
    },
  },
  setup(props, { emit }) {
    const selectedUserReportIdModel = useVModel(props, 'selectedUserReportId', emit)
    const displayModeModel = useVModel(props, 'displayMode', emit)
    const userIdModel = useVModel(props, 'userId', emit)

    const shouldSelectUser = computed(() => {
      return displayModeModel.value !== 'monthly_all_users_sum'
    })

    const isPending = computed(() => {
      return shouldSelectUser.value && !userIdModel.value
    })

    const aggregatable = computed(() => {
      if (props.loading || isPending.value) {
        return {
          id: '',
          type: '',
          attributes: {
            businessRules: { data: [] },
            contexts: { data: [] },
            id: '',
            type: '',
            name: '',
            description: '',
          },
        }
      }

      return props.report
    })

    return {
      selectedUserReportIdModel,
      displayModeModel,
      userIdModel,
      isPending,
      aggregatable,
      displayModes: [
        {
          text: '全ユーザー/月別合計',
          value: 'monthly_all_users_sum',
        },
        {
          text: 'ユーザー別/月別日次',
          value: 'monthly_daily',
        },
        {
          text: 'ユーザー別/月別合計',
          value: 'monthly_sum',
        },
      ],
      icons: {
        mdiReload,
        mdiDotsVertical,
      },
    }
  },
}
</script>

<style lang="scss">
@import '~@core/preset/preset/mixins.scss';

@include theme--child(user-report-table) using ($material) {
  th.fixed-cell,
  td.fixed-cell {
    position: sticky !important;
    position: -webkit-sticky !important;
    left: 0;
    z-index: 3;
    background: map-deep-get($material, 'app-bar');
  }

  th.fixed-cell {
    z-index: 4;
  }

  .v-progress-linear {
    z-index: 5;
  }
}

.user-report-table {
  white-space: nowrap;
  .v-input__append-outer {
    margin-top: 0;
    margin-bottom: 0;
  }
}
</style>
