var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.displayModeModel === 'monthly_daily')?_c('RangeDailyTable',{attrs:{"aggregation":_vm.aggregatable,"loading":_vm.loading || _vm.isPending},on:{"save":function($event){return _vm.$emit('save', $event)},"reload":function($event){return _vm.$emit('reload', $event)}},scopedSlots:_vm._u([{key:"toolbar-title",fn:function(ref){
var aggregation = ref.aggregation;
return [(aggregation.attributes)?_c('UserReportTableTitle',{attrs:{"selected-user-report-id":_vm.selectedUserReportIdModel,"user-reports":_vm.userReports,"default-title":aggregation.attributes.name},on:{"update:selectedUserReportId":function($event){_vm.selectedUserReportIdModel=$event},"update:selected-user-report-id":function($event){_vm.selectedUserReportIdModel=$event}}}):_vm._e()]}},{key:"toolbar-filters",fn:function(){return [_c('UserReportTableFilters',{attrs:{"display-mode":_vm.displayModeModel,"user-id":_vm.userIdModel,"user-report":_vm.userReport},on:{"update:displayMode":function($event){_vm.displayModeModel=$event},"update:display-mode":function($event){_vm.displayModeModel=$event},"update:userId":function($event){_vm.userIdModel=$event},"update:user-id":function($event){_vm.userIdModel=$event},"change-display":function($event){return _vm.$emit('change-display', $event)}}})]},proxy:true},{key:"toolbar-menu",fn:function(ref){
var headers = ref.headers;
var items = ref.items;
return [_c('UserReportTableMenu',{attrs:{"loading":_vm.loading || _vm.isPending,"available-users":_vm.availableUsers,"user-report":_vm.userReport,"headers":headers,"items":items},on:{"fetch-available-users":function($event){return _vm.$emit('fetch-available-users', $event)},"create-report-user":function($event){return _vm.$emit('create-report-user', $event)},"destroy-report-user":function($event){return _vm.$emit('destroy-report-user', $event)}}})]}}],null,false,2699072780)}):(_vm.displayModeModel === 'monthly_sum')?_c('RangeSumTable',{attrs:{"aggregation":_vm.aggregatable,"loading":_vm.loading || _vm.isPending},on:{"reload":function($event){return _vm.$emit('reload', $event)}},scopedSlots:_vm._u([{key:"toolbar-title",fn:function(ref){
var aggregation = ref.aggregation;
return [(aggregation.attributes)?_c('UserReportTableTitle',{attrs:{"selected-user-report-id":_vm.selectedUserReportIdModel,"user-reports":_vm.userReports,"default-title":aggregation.attributes.name},on:{"update:selectedUserReportId":function($event){_vm.selectedUserReportIdModel=$event},"update:selected-user-report-id":function($event){_vm.selectedUserReportIdModel=$event}}}):_vm._e()]}},{key:"toolbar-filters",fn:function(){return [_c('UserReportTableFilters',{attrs:{"display-mode":_vm.displayModeModel,"user-id":_vm.userIdModel,"user-report":_vm.userReport},on:{"update:displayMode":function($event){_vm.displayModeModel=$event},"update:display-mode":function($event){_vm.displayModeModel=$event},"update:userId":function($event){_vm.userIdModel=$event},"update:user-id":function($event){_vm.userIdModel=$event},"change-display":function($event){return _vm.$emit('change-display', $event)}}})]},proxy:true},{key:"toolbar-menu",fn:function(ref){
var headers = ref.headers;
var items = ref.items;
return [_c('UserReportTableMenu',{attrs:{"loading":_vm.loading || _vm.isPending,"available-users":_vm.availableUsers,"user-report":_vm.userReport,"headers":headers,"items":items},on:{"fetch-available-users":function($event){return _vm.$emit('fetch-available-users', $event)},"create-report-user":function($event){return _vm.$emit('create-report-user', $event)},"destroy-report-user":function($event){return _vm.$emit('destroy-report-user', $event)}}})]}}])}):(_vm.displayModeModel === 'monthly_all_users_sum')?_c('RangeSumTable',{attrs:{"aggregation":_vm.aggregatable,"loading":_vm.loading},on:{"reload":function($event){return _vm.$emit('reload', $event)}},scopedSlots:_vm._u([{key:"toolbar-title",fn:function(ref){
var aggregation = ref.aggregation;
return [(aggregation.attributes)?_c('UserReportTableTitle',{attrs:{"selected-user-report-id":_vm.selectedUserReportIdModel,"user-reports":_vm.userReports,"default-title":aggregation.attributes.name},on:{"update:selectedUserReportId":function($event){_vm.selectedUserReportIdModel=$event},"update:selected-user-report-id":function($event){_vm.selectedUserReportIdModel=$event}}}):_vm._e()]}},{key:"toolbar-filters",fn:function(){return [_c('UserReportTableFilters',{attrs:{"display-mode":_vm.displayModeModel,"user-id":_vm.userIdModel,"user-report":_vm.userReport},on:{"update:displayMode":function($event){_vm.displayModeModel=$event},"update:display-mode":function($event){_vm.displayModeModel=$event},"update:userId":function($event){_vm.userIdModel=$event},"update:user-id":function($event){_vm.userIdModel=$event},"change-display":function($event){return _vm.$emit('change-display', $event)}}})]},proxy:true},{key:"toolbar-menu",fn:function(ref){
var headers = ref.headers;
var items = ref.items;
return [_c('UserReportTableMenu',{attrs:{"loading":_vm.loading,"available-users":_vm.availableUsers,"user-report":_vm.userReport,"headers":headers,"items":items},on:{"fetch-available-users":function($event){return _vm.$emit('fetch-available-users', $event)},"create-report-user":function($event){return _vm.$emit('create-report-user', $event)},"destroy-report-user":function($event){return _vm.$emit('destroy-report-user', $event)}}})]}}])}):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }